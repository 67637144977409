import { useEffect, useRef } from 'react';

import { isFunction } from '../../utils/types/types';

export const useListenForGlobal = ( data ) => {
  const { callback, watch, delay = 300 } = data || {};

  const interval = useRef();

  useEffect( () => {
    global.clearInterval( interval.current );

    if( !isFunction( watch ) ){
      return;
    }

    interval.current = global.setInterval( () => {
      const payload = watch();

      if( !payload ){
        return;
      }

      callback( { payload } );
      global.clearInterval( interval.current );
      interval.current = 'expired';
    }, delay );

    return () => global.clearInterval( interval.current );
  }, [] );
};
