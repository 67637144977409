/**
 * Footer component will be the main Footer Component for POC
 *
 * @module views/components/Footer
 * @memberof -Common
 */
import './Footer.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useListenForGlobal } from '@ulta/core/hooks/useListenForGlobal/useListenForGlobal';
import { CLIENT_ACTION_TYPES } from '@ulta/core/utils/constants/action';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

/**
 * Represents a Footer component
 *
 * @method
 * @param {FooterProps} props - React properties passed from composition
 * @returns Footer
 */
export const Footer = React.forwardRef( ( props, _ ) => {
  const { footerTagLine, modules, copyrightInformation, secondaryFooterLinks } = props;
  if( !modules ){
    return null;
  }

  return (
    <footer className='Footer'
      role='contentinfo'
      id='FooterWrapper'
    >
      <div className='Footer__wrapper'>
        <div className='Footer__footerTagline'>
          { footerTagLine &&
            <UltaAsset { ...footerTagLine } />
          }
        </div>
        { modules?.map( ( i, index ) => {
          return (
            <AsyncComponent key={ `${i.id}:${index}` }
              { ...i }
            />
          );
        } ) }
      </div>
      { secondaryFooterLinks && (
        <div className='Footer__bottomWrapper'>
          <div className='Footer__bottomWrapperRow'>
            <Text htmlTag='p'
              textStyle='body-3'
            >
              { copyrightInformation }
            </Text>

            <ul className='Footer__list'>
              { secondaryFooterLinks.map( ( footerItem, index ) => (
                <li className='Footer__listItem'
                  key={ index }
                >
                  { footerItem?.clientActionType === CLIENT_ACTION_TYPES.ONETRUST_CLICK &&
                    <OneTrustLink { ...footerItem } />
                  }
                  { footerItem?.clientActionType !== CLIENT_ACTION_TYPES.ONETRUST_CLICK &&
                    <Link_Huge target='_blank'
                      url={ footerItem.url }
                      tertiary
                      compact
                      action={ footerItem }
                    >
                      { footerItem.label }
                    </Link_Huge>
                  }
                </li>
              ) ) }
            </ul>
          </div>
        </div>
      ) }
    </footer>
  );
} );

/**
 * Renders a OneTrustLink component.
 *
 * @param { Object } props - The component's properties.
 * @returns { Button } The rendered OneTrustLink component.
 */
export const OneTrustLink = ( props ) => {
  const footerItem = handleEmptyObjects( props );
  const [display, setDisplay] = useState( false );

  useListenForGlobal( {
    watch: () => global.OneTrust?.ToggleInfoDisplay,
    callback: ( ) => {
      setDisplay( true );
    }
  } );

  if( !display ){
    return null;
  }

  return (
    <Button
      variant='unstyled'
      className='Footer__ot-sdk-show-settings'
      action={ footerItem }
      label={ footerItem.label }
      onClick={ global.OneTrust.ToggleInfoDisplay }
      title={ footerItem.label }
      value={ footerItem.label }
    />
  );
};

/**
 * Property type definitions
 * @typedef FooterProps
 * @type {object}
 * @property {string} id - sets the content ID for the Footer
 */
export const propTypes = {
  id: PropTypes.string
};

Footer.propTypes = propTypes;
Footer.displayName = 'Footer';

export default Footer;
